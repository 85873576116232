var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{staticStyle:{"padding":"5px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('s-date',{attrs:{"label":"Fecha Inicio"},model:{value:(_vm.filter.DateBegin),callback:function ($$v) {_vm.$set(_vm.filter, "DateBegin", $$v)},expression:"filter.DateBegin"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('s-date',{attrs:{"label":"Fecha Fin"},model:{value:(_vm.filter.DateEnd),callback:function ($$v) {_vm.$set(_vm.filter, "DateEnd", $$v)},expression:"filter.DateEnd"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('s-toolbar',{staticClass:"mr-4 ml-2",staticStyle:{"display":"flex"},attrs:{"dark":"","color":'#BAB6B5',"transferir":"","print":""},on:{"transferir":function($event){return _vm.tranfer()},"print":function($event){return _vm.printQR()}}}),_c('v-data-table',{attrs:{"items":_vm.itemsSendEnd,"item-key":"LlpID","dense":"","headers":_vm.headerSendEnd,"items-per-page":15,"disable-sort":"","group-by":"IDChangeFsma","show-select":""},scopedSlots:_vm._u([{key:"item.separator",fn:function(row){return [_c('v-chip',{attrs:{"small":"","dark":"","color":"warning"}},[_vm._v(" _ ")])]}},{key:"item.LlpID",fn:function(row){return [_c('v-chip',{attrs:{"small":"","dark":"","color":"success"}},[_vm._v(" "+_vm._s(row.item.LlpID)+" ")])]}},{key:"item.LlpIDm",fn:function(row){return [_c('v-chip',{attrs:{"small":"","dark":"","color":"success"}},[_vm._v(" "+_vm._s(row.item.LlpIDm)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"display":"none"},attrs:{"justify":"center","cols":"12"}},_vm._l((_vm.selected),function(item){return _c('v-col',{attrs:{"cols":"12"}},[_c('qr-code',{attrs:{"text":item.LlpID +
							', Jabas: ' +
							item.LlpQuantityJaba +
							',' +
							item.TypeCaliberName +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName +
							',' +
							item.TypeDestinyName +
							',' +
							item.TypeDestiny,"error-level":"H","hidden":""}}),_c('br')],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }