import Service from "../Service";
import Vue from "vue";
const resource = "/LotProductionCalibrated/";

export default {

    GetDetailQR(LlpID, requestID) {
        return Service.post(resource + "GetDetailQR", "", {
            params: { LlpID: LlpID, requestID: requestID },
        });
    },


    pagination(parameters, requestID) {
        return Service.post(resource + "paginationPrintQR", parameters, {
            params: { requestID: requestID },
        });
    },
};